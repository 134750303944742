import React from 'react';
import { detect } from 'detect-browser';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import './App.scss';
import Logo from '../img/logo.png';

const App = () => {
  const browser = detect();
  const browserConfig = {
    other: {
      text: 'Add AirReview - It\'s Free',
      url: 'https://chrome.google.com/webstore/detail/airreview/plkdnpjpnhhnmigmekaocdfokkmebdnm'
    },
    chrome: {
      text: 'Add it to Chrome - It\'s Free',
      url: 'https://chrome.google.com/webstore/detail/airreview/plkdnpjpnhhnmigmekaocdfokkmebdnm'
    },
    firefox: {
      text: 'Add it to Firefox - It\'s Free',
      url: 'https://addons.mozilla.org/en-US/firefox/addon/airreview'
    }
  };

  const buttonConfig = browserConfig[browser.name] || browserConfig['other'];

  return (
    <div id="app">
      <Grid container className="container" alignContent="flex-start" direction="column">
        <Grid item container className="container__logo" alignItems="flex-end">
          <img src={Logo} className="container__logo__image" alt="air review"/>
          <strong>AirReview</strong>
        </Grid>
        <Grid item container className="container__body" alignItems="center" justify="flex-end">
          <Card className="container__body__card">
            <CardContent>
              <h1 className="container__body__card__h1">Host with confidence.</h1>
              <p className="container__body__card__p">
                As an Airbnb host, you can tell a lot about potential guests by the types of reviews they've left for other hosts.
              </p>
              <p className="container__body__card__p">
                AirReview makes it easy to see reviews Airbnb guests have left, right on their profile page.
              </p>
              <Button variant="contained" href={buttonConfig.url} className="container__body__card__button">
                {buttonConfig.text}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
